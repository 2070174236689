import React from 'react'
import TopNav from './TopNav'
import TapMenu from './TapMenu'

export default function Status() {
  return (<>
    <section>
      <div className="conatiner">
          <TapMenu/>
      </div>
    </section>
    </>
  )
}
